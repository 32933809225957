import { createFetch } from '@vueuse/core'
import { DateTime } from 'luxon'
import { env } from '../env'
import { useDevMode } from '~/composables/useDevNode'
import { useExecutionTime } from '~/composables/useExecutionTime'
import { useApiErrorLogger } from "~/composables/useApiErrorLogger";

const { setApiError } = useApiError()
const { addError } = useApiErrorLogger()
const { isEnabled, devHttpHeaders } = storeToRefs(useDevMode())

function saveAs(fileName, data) {
    const xlsxUrl = window.URL.createObjectURL(data)
    const a = document.createElement('a')
    a.href = xlsxUrl
    a.download = fileName
    document.body.appendChild(a) // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click()
    a.remove()
}

const startTime = ref(0)
const executionTime = ref(0)

export const useFetch = createFetch({
    baseUrl: env.BACKEND_URL,
    //    combination: 'overwrite',
    options: {
        async beforeFetch({ options, url, query }: any) {

            const { incrementRequest } = useExecutionTime()
            incrementRequest()
            startTime.value = Date.now()
            const currentUser = JSON.parse(window.localStorage.getItem('user') || '{}')
            const parsedUrl = new URL(url)

            options.headers.Authorization = `Bearer ${currentUser?.token}`

            if (isEnabled.value && !parsedUrl.pathname.includes('/users/')) {
                options.headers = {
                    ...options.headers,
                    ...devHttpHeaders.value,
                }
            }

            switch (parsedUrl.searchParams.get('downloadFileType')) {
                case 'pdf':
                    options.headers['Content-Type'] = 'application/pdf'
                    options.headers['Velcloud-ClientTime'] = DateTime.now().toISO()
                    break
                case 'xlsx':
                    options.headers['Content-Type'] =
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    options.headers['Velcloud-ClientTime'] = DateTime.now().toISO()
                    break
            }

            if (parsedUrl.searchParams.has('locationIDs')) {
                parsedUrl.searchParams.delete('groupIDs')
            }

            if (parsedUrl.searchParams.has('lastUpdate')) {
                parsedUrl.searchParams.delete('lastUpdate')
            }

            if (parsedUrl.searchParams.get('sort') === '') {
                parsedUrl.searchParams.delete('sort')
            }
            if (parsedUrl.searchParams.get('order') === '') {
                parsedUrl.searchParams.delete('order')
            }

            const locale = localStorage.getItem('locale') ?? 'en'

            parsedUrl.searchParams.set('lang', locale)

            url = parsedUrl.toString()

            return { options, url, query }
        },
        async onFetchError({ error, response, data }) {

            const urlObj = new URL(response?.url)

            executionTime.value = Date.now() - startTime.value


            const text = await response?.text();
            if(response) {
                addError(response.status, response.statusText, urlObj.pathname + urlObj.search, text);
            }
            setApiError(error, text)


            if (urlObj.searchParams.get('downloadFileName')) {
                const errorStore = useErrorStore()

                if (data instanceof Blob) {
                    const body = await data.text()

                    if (body === 'Too many invoices') {
                        errorStore.setError(body)

                        return { error, response, data }
                    }
                }


                errorStore.setError('An error has occurred, please try again later.')
            }
            return { error, response, data }
        },
        async afterFetch({ data, response }) {

            executionTime.value = Date.now() - startTime.value

            const { addContentLength } = useExecutionTime()

            const urlObj = new URL(response.url)
            if (data instanceof Blob) {
                const downloadFileName = urlObj.searchParams.get('downloadFileName') ?? new Date().getTime().toString()
                const downloadFileType = urlObj.searchParams.get('downloadFileType')
                const dateTimeString = DateTime.fromISO(new Date().toISOString()).toFormat('yLLddhhmmss')

                saveAs(
                    `${downloadFileName}-${dateTimeString}.${downloadFileType}`,
                    downloadFileType === 'json'
                        ? new Blob([JSON.stringify(JSON.parse(await data.text()), null, 4)])
                        : data,
                )
            } else {
                try {
                    addContentLength(
                        response.headers.get('Content-Length') || JSON.stringify(await response.json()).length,
                    )
                } catch (e) {}
            }

            return { data, response }
        }
    },
    fetchOptions: {
        mode: 'cors',
    }
})
