footer:
  menu:
    home: Accueil
    terms: Conditions générales
    cookies_policy: Politique de cookies
    contact_us: Nous contacter
Email or password is incorrect: Email ou mot de passe incorrect
Something went wrong. Please try again later: Un problème est survenu. Veuillez réessayer plus tard
Logout: Déconnexion
Profile Settings: Paramètres
Terms of Service: Conditions d'utilisation
Setup: Configuration
Admin: Admin
Users: Utilisateurs
Dashboard: Tableau de bord
Privacy Policy: Politique de confidentialité
Contact Us: Nous contacter
Product Suggestion: Suggestion de produit
Help and support: Aide et support
Send us feedback: Nous contacter
Hello: Bonjour
Analytics: Analytique
Reports: Rapports
Transactions: Transactions
Discounts: Escomptes
Employee sales: Ventes par employés
Gift card: Carte cadeau
Items sales by category: Items par catégorie
Items sales: Items de vente
Net sales: Ventes nettes
Sales & revenue: Ventes et revenus
Sales per hour: Ventes horaires
Voids, refunds & corrections: Annulations, remb. et corrections
Financial: Finances
Audit: Audit
Category sales: Ventes par catégories
Discount: Escompte
Location sales: Ventes par établissements
Summary: Sommaire
Check: Facture
Check summary: Sommaire de facture
Check search: Recherche de facture
Menu: Menu
Categories: Catégories
Sub-categories: Sous-catégories
Items: Items
Item profile: Profils d'items
Popups: Popups
Combos: Combos
Mix and match: Méli-mélo
Coursing: Services
Concepts: Concepts??
Counters: Compteurs
Business: Entreprise
Taxes: Taxes
Settings: Paramètres
Locations: Établissements
Groups: Groupes
Resellers: Revendeurs
Employees: Employés
Coupons: Coupons
Language: Langue
System: Système
Location status: Statut des établissements
Check the status of all locations for the selected groups.: Vérifier le statut de tous les établissements pour les groupes sélectionnés.
Create, configure, or deactivate current users.: Créer, configurer ou désactiver les utilisateurs actuels.
Browse and configure your locations.: Parcourir et configurer vos établissements.
Browse and configure your groups.: Parcourir et configurer vos groupes.
Browse and configure your resellers.: Parcourir et configurer vos revendeurs.
Create, configure, or deactivate current employees.: Créer, configurer ou désactiver les employés actuels.
Browse and configure your coupons.: Parcourir et configurer vos coupons.
View overall statistics.: Voir les statistiques globales.
Search for specific checks and details.: Rechercher des factures et détails spécifiques.
Review check summaries for a groupe, location, and given period of time.: Revue des sommaires de factures pour un groupe, un établissement et une période donnée.
Browse and configure your categories: Parcourir et configurer vos catégories.
Browse and configure your sub-categories.: Parcourir et configurer vos sous-catégories.
Browse and configure your items.: Parcourir et configurer vos items.
Browse and configure your item profiles.: Parcourir et configurer vos profils d'items.
Browse and configure your popups.: Parcourir et configurer vos popups.
Browse and configure your combos.: Parcourir et configurer vos combos.
Browse and configure your mix and match.: Parcourir et configurer vos méli-mélos.
Browse and configure your coursings.: Parcourir et configurer vos services.
Browse and configure your concepts.: Parcourir et configurer vos concepts.
Browse and configure your counters.: Parcourir et configurer vos compteurs.
Configure your business.: Configurer votre entreprise.
Browse and configure your taxes.: Parcourir et configurer vos taxes.
Browse and configure your tax categories.: Parcourir et configurer vos catégories de taxes.
Browse and configure your discounts.: Parcourir et configurer vos escomptes.
Drag and drop the sections to create your own dashboard.: Faites glisser et déposez les sections pour créer votre propre tableau de bord.
Generate a report to browse activated discounts.: Générer un rapport pour parcourir les escomptes activés.
Generate a report to browse employee sales.: Générer un rapport pour parcourir les ventes par employés.
Generate a report to browse gift card transactions.: Générer un rapport pour parcourir les transactions de carte cadeau.
Generate a report to browse sales by categories.: Générer un rapport pour parcourir les ventes par catégories.
Generate a report to browse items sales.: Générer un rapport pour parcourir les ventes d'items.
Review your net sales details by clicking on the items in this table.: Revue des détails de vos ventes nettes en cliquant sur les items de ce tableau.
Generate a report to browse sales & revenue.: Générer un rapport pour parcourir les ventes et revenus.
Generate a report to browse sales per hour.: Générer un rapport pour parcourir les ventes horaires.
Generate a report to browse voids, refunds, and corrections.: Générer un rapport pour parcourir les annulations, remboursements et corrections.
Generate a report to browse audits.: Générer un rapport pour parcourir les audits.
Generate a report to browse category sales.: Générer un rapport pour parcourir les ventes par catégories.
Generate a report to browse sales by locations.: Générer un rapport pour parcourir les ventes par établissements.
Generate an easy-to-read general summary.: Générer un sommaire facile à lire.
Explore your latest reports.: Explorer vos derniers rapports.
Review your financial reports: Revue de vos rapports financiers.
Review your transaction reports: Revue de vos rapports de transactions.
Review your check reports: Revue de vos rapports de factures.
Access: Accéder
Browse and configure your menu.: Parcourir et configurer votre menu.
Configure your settings.: Configurer vos paramètres.
Browse and configure your categories.: Parcourir et configurer vos catégories.
Email: Courriel
First name: Prénom
Last name: Nom
Type: Type
Enabled: Activé
Reseller name: Nom du revendeur
Created: Date de création
Display: Afficher
items: items
Display of: Affichage des
to: à
of: de
Go to page: Aller à la page
Edit: Éditer
Delete: Supprimer
Active: Actif
Inactive: Inactif
Merchant: Marchand
Payfacto employee: Employé Payfacto
Agent: Agent
users: utilisateurs
locations: établissements
Logo: Logo
Group: Groupe
Name: Nom
Reporting display name: Nom d'affichage
Address: Adresse
Country: Pays
License: Licence
Version: Version
External ID: ID externe
Last invoice: Dernière facture
Canada: Canada
United Kingdom: Royaume-Uni
Bahamas: Bahamas
Guadeloupe: Guadeloupe
United States: États-Unis
groups: groupes
Corporate: Corporatif
Banner: Marque
Custom: Personnalisé
Online: En ligne
Warning: Avertissement
Offline: Hors ligne
POS Type: Type de POS
Last activity: Dernière activité
Status: Statut
Show: Afficher
Hide: Cacher
Hide filters: Cacher les filtres
Show filters: Afficher les filtres
License number: Numéro de licence
POS version: Version POS
Location: Établissement
Manage filters: Gérer les filtres
Apply: Appliquer
Clear: Effacer
All status: Tous les statuts
All POS type: Tous les types de POS
OK: OK
Cancel: Annuler
Select all: Sélectionner tout
Deselect all: Désélectionner tout
Oops!: Oups!
It seems there are no results for your search. Try again with different filters.: Il semble qu'il n'y ait aucun résultat pour votre recherche.<br />Essayez à nouveau avec des filtres différents.
Group name: Nom du groupe
Group type: Type de groupe
Enter name: Saisir le nom
All types: Tous les types
Select one or more groups: Sélectionnez un ou plusieurs groupes
Enable multiselect to choose more than one or all groups.: Activer la sélection multiple pour choisir plus d'un ou tous les groupes
Multiselect: Sélection multiple
All groups: Tous les groupes
Download as PDF: Télécharger au format PDF
Download as Excel: Télécharger au format Excel
Download as JSON: Télécharger au format JSON
Send by email: Envoyer par courriel
Select a POS type: Sélectionnez un type de POS
12345: 12345
1.2: 1.2
10: 10
Select a status: Sélectionnez un statut
Refresh: Actualiser
Show selected items only: Afficher uniquement les items sélectionnés
Enter email address: Saisir le courriel
Email address: Adresse courriel
Full name: Nom complet
Create new user: Créer un nouvel utilisateur
Create new group: Créer un nouveau groupe
Enter the required information to create a new group.: Saisir les informations requises pour créer un nouveau groupe.
Wait...: Patientez...
Nothing to see here for now. Select a group to generate a coupons list.: Rien à voir pour le moment. Sélectionnez un groupe pour générer une liste de coupons.
Enter coupon code: Saisir le code du coupon
Enter coupon name: Saisir le nom du coupon
Code: Code
Amount: Montant
Multi: Multi
Single: Simple
Multiple: Multiple
Expiry: Expiration
Redeemed: Racheté
coupons: coupons
indicators:
  drawerOpenings:
    title: Ouverture de tiroir
    description: Nombre d’ouvertures de tiroir sans transaction de vente.
  giftCardAmount:
    title: Montant des recharges de carte cadeau
    description: Somme des chargements de cartes cadeaux.
  itemCorrections:
    title: Nombre de corrections d'items
    description: Nombre de corrections d'items.
  itemCorrectionsAmount:
    title: Montant des corrections d'items
    description: Somme des corrections d'items.
  loyaltiesAmount:
    title: Montant des loyautés
    description: Montant total des loyautés.
  paidOuts:
    title: Déboursés
    description: Nombre de paiements.
  paidOutsAmount:
    title: Montant des paiements
    description: Somme des montants payés aux fournisseurs.
  refunds:
    title: Nombre de remboursements
    description: Nombre de remboursements.
  refundsAmount:
    title: Montant des remboursements
    description: Somme des remboursements.
  voids:
    title: Annulations
    description: Nombre d'annulations.
  voidsAmount:
    title: Montant des annulations
    description: Somme des annulations.
  grossSalesAmount:
    title: Montant des ventes brutes
    description: Somme de ventes facturées (hors taxes) avant application des escomptes (globaux et d’items).
  discountsAmount:
    title: Montant des escomptes
    description: Somme des escomptes globaux et d’items.
  invoicesDiscount:
    title: Rabais sur factures
    description: Montant total des rabais sur factures.
  itemsDiscount:
    title: Rabais sur items
    description: Montant total des rabais sur items.
  netSalesAmount:
    title: Montant des ventes nettes
    description: Somme des ventes facturées (hors taxes) après application des escomptes.
  refundsTotalAmount:
    title: Montant total des remboursements
    description: Montant total des remboursements.
  taxesAmount:
    title: Montant des taxes
    description: Somme des taxes.
  gratuityAmount:
    title: Montant des pourboires
    description: Montant total des pourboires.
  tipsAmount:
    title: Montant des pourboires
    description: Somme des pourboires.
  tipsTotalAmount:
    title: Tips total amount
    description: Total amount of tips.
  customers:
    title: Nombre de clients
    description: Nombre de clients.
  meals:
    title: Nombre de repas
    description: Nombre de repas vendus, basé sur la configuration du nombre de repas des items.
  orders:
    title: Nombre de factures
    description: Nombre de factures.
  debitCreditTotalAmount:
    title: Montant total débit-crédit
    description: Montant total débit-crédit.
  debitCreditTipsAmount:
    title: Montant des pourboires débit-crédit
    description: Montant total des pourboires débit-crédit.
  averageNetSales:
    title: Revenu net par facture
    description: Revenu par vente calculé sur les ventes nettes.
  averageGrossSales:
    title: Revenu brut par facture
    description: Revenu par vente calculé sur les ventes brutes.
  roundingAmount:
    title: Montant des arrondis
    description: Somme de l'arrondissement.
  cashTipsAmount:
    title: Montant des pourboires en espèces
    description: Montant total des pourboires en espèces.
  surchargeAmount:
    title: Montant des frais supplémentaires
    description: Somme des surcharges.
  netCashAmount:
    title: Trésorerie nette
    description: Somme du mode de paiement argent, plus l’arrondissement si applicable.
  cashDepositAmount:
    title: Montant du dépôt en espèces
    description: Somme des ventes brutes payées comptant, moins les escomptes et le pourboire.
  paidInsAmount:
    title: Encaissés
    description: Somme des montants encaissés.
  cashDiscountAmount:
    title: Montant des rabais en espèces
    description: Somme des rabais en espèce.
  locationSales:
    title: Ventes par établissement
    description: Ventes par établissement.
  itemsSales:
    title: Ventes par item
    description: Ventes par item.
  tenderTypesSales:
    title: Type de paiement
    description: Type de paiement.
  departmentsSales:
    title: Ventes par centres et revenus
    description: Ventes par centres et revenus.
  zonesSales:
    title: Ventes par mode de commande
    description: Ventes par mode de commande.
  employeesSales:
    title: Ventes par employé
    description: Ventes par employé.
  discountsSales:
    title: Détail des escomptes
    description: Détail des escomptes.
  workstationsSales:
    title: Ventes par caisse
    description: Ventes par caisse.
  bigDivisionsSales:
    title: Ventes par catégorie
    description: Ventes par catégorie.
  modesSales:
    title: Ventes par mode
    description: Ventes par mode.
  hourlySales:
    title: Ventes brutes par heure
    description: Ventes brutes par heure.
  cumulativeHourlySales:
    title: Ventes cumulatives par heure
    description: Ventes cumulatives par heure.
  grossSalesPerMeal:
    title: Revenu brut par repas
    description: Revenu par repas calculé sur les ventes brutes.
  netSalesPerMeal:
    title: Ventes nettes par repas
    description: Revenu par repas calculé sur les ventes nettes.
  discountsPercentage:
    title: Pourcentage des escomptes
    description: Pourcentage d'escomptes calculé sur les ventes brutes pour les escomptes globaux et d’items.
  grossSalesPerCustomer:
    title: Revenu brut par client
    description: Revenu par client calculé sur les ventes brutes.
  netSalesPerCustomer:
    title: Ventes nettes par client
    description: Revenu par client calculé sur les ventes nettes.
  consolidatedHourlySales:
      title: Ventes brutes par heure
      description: Ventes brutes par heure
CAD: CAD
Show data trends: Afficher les tendances des données
Customization: Personnalisation
Manage your dashboard information. You can enable and disable informations.: Gérez les informations de votre tableau de bord. Vous pouvez activer et désactiver les informations.
indicator: Indicator
Reset dashboard: Réinitialiser le tableau de bord
Select a group: Sélectionnez un groupe
Enter license: Saisir la licence
Enter version: Saisir la version
Enter address: Saisir l'adresse
Enter display name: Saisir le nom d'affichage
Enter location name: Saisir le nom de l'établissement
Enter brand name: Saisir le nom de la marque
Enter enterprise name: Saisir le nom de l'entreprise
Enter group name: Saisir le nom du groupe
Location name: Nom de l'établissement
Display name: Nom d'affichage
Add new: Ajouter
All: Tous
Enter the required information to create a new user.: Saisir les informations requises pour créer un nouvel utilisateur.
User status: Statut de l'utilisateur
Create user: Créer un utilisateur
Back: Retour
Sorry, an error has occurred. Please try again.: Désolé, une erreur s'est produite. Veuillez réessayer.
Select type: Sélectionnez un type
Reset password: Réinitialiser le mot de passe
Modify: Modifier
Save: Enregistrer
General information: Informations générales
Contact information and user type.: Informations de contact et type d'utilisateur.
User type: Type d'utilisateur
Reseller: Revendeur
Phone number: Numéro de téléphone
Extension: Extension
Select a reseller: Sélectionnez un revendeur
Back to users: Retour aux utilisateurs
Save & quit: Enregistrer et quitter
user_edit_sub_title: "Créé par {username} / Dernière mise à jour {last_update}"
Success: Succès
User updated successfully.: Utilisateur mis à jour avec succès.
Restrict or expand the user access to back-end functions.: Restreindre ou étendre l'accès de l'utilisateur aux fonctions back-end.
User permissions: Autorisations de l'utilisateur
Select a location: Sélectionnez un établissement
Select a permission: Sélectionnez une autorisation
Select a type: Sélectionnez un type
Full access: Accès complet
Add permission: Ajouter une autorisation
Create a password reset token.: Envoyer un e-mail de réinitialisation du mot de passe à un utilisateur.
Request successfully completed.: Demande effectuée avec succès.
User created successfully.: Utilisateur créé avec succès.

Are you sure you want to create a password reset token for this user?: Êtes-vous sûr de vouloir réinitialiser le mot de passe de cet utilisateur? Cela enverra un e-mail de réinitialisation du mot de passe à cette adresse.
Are you sure you want to delete the %s group? This operation will also delete the following information related to this group: Êtes-vous sûr de vouloir supprimer le groupe <b>{name}</b>? Cette opération supprimera également les informations suivantes liées à ce groupe
Warning!: Attention!
Products (ex coupons): "Produits (ex: coupons)"
Links to locations: Liens vers les établissements
To delete this group, please enter the name %s. in the input below and click Delete group.: Pour supprimer ce groupe, veuillez saisir le nom <b>{name}</b> dans le champ ci-dessous et cliquer sur Supprimer le groupe.
Delete group: Supprimer le groupe
The new user has been created successfully.: L'utilisateur a été créé avec succès.
The changes have been saved successfully.: Les modifications ont été enregistrées avec succès.
The password reset token has been sent successfully.: Le jeton de réinitialisation du mot de passe a été envoyé avec succès.
The group has been deleted successfully.: Le groupe a été supprimé avec succès.
Group's information and logo.: Informations et logo du groupe.
Enter or edit the information, permissions, and locations for this group.: Saisir ou modifier les informations, autorisations et établissements pour ce groupe.
Group configuration: Configuration du groupe
Access the list of users with permissions for this group.: Accéder à la liste des utilisateurs avec autorisations pour ce groupe.
Back to groups: Retour aux groupes
Add location(s): Ajouter établissements(s)
Select and add one or more location(s) to this group.: Sélectionner et ajouter des établissements à ce groupe.
Location(s): Établissements(s)
Select one or more: Sélectionner un ou plusieurs
User configuration: Configuration de l'utilisateur
Enter or edit the information and permissions for this user.: Saisir ou modifier les informations et autorisations pour cet utilisateur.
The new group has been created successfully.: Le groupe a été créé avec succès.
Get access to the settings and configuration for the entire Veloce content.: Accéder aux paramètres et à la configuration de l'ensemble du contenu Veloce.
Manage and regroup the various entities that are utilizing the Veloce solution.: Gérer et regrouper les différentes entités qui utilisent la solution Veloce.
Access status and other information.: Statut d'accès et autres informations.
An error has occurred or your link has expired.: Une erreur s'est produite ou votre lien a expiré.
Your password has been successfully updated.: Votre mot de passe a été mis à jour avec succès.
Weak: Faible
Medium: Moyen
Strong: Fort
Pick a password: Choisir un mot de passe
All locations: Tous les établissements
Enter the required information to create a new coupon.: Saisir les informations requises pour créer un nouveau coupon.
Create new coupon: Créer un nouveau coupon
Usage: Utilisation
Select usage: Sélectionnez une utilisation
Coupon settings: Paramètres du coupon
PLU 1 Amount: Montant PLU 1
PLU 2 Amount: Montant PLU 2
PLU 1: PLU 1
PLU 2: PLU 2
Activate coupon: Activer le coupon
Enter expiration: Saisir l'expiration
The coupon has been created successfully.: Le coupon a été créé avec succès.
Invalid column name: Nom de colonne non valide à la position {column}.<br><br>"{expected}" <> "{current}".
Your CSV file is empty: Votre fichier CSV est vide.
Invalid code length: Longueur de code non valide à la ligne {line}.
Missing name: Nom manquant à la ligne {line}.
Invalid value in column Active: Valeur non valide dans la colonne Active à la ligne {line}.
Invalid value in column Plu1: Valeur non valide dans la colonne Plu1 à la ligne {line}.
Invalid value in column Plu1Amount: Valeur non valide dans la colonne Plu1Amount à la ligne {line}.
Invalid value in column Plu2Amount: Valeur non valide dans la colonne Plu2Amount à la ligne {line}.
Invalid value in column Coupon type: Valeur non valide dans la colonne Coupon type à la ligne {line}.
Invalid value in column Expires: Valeur non valide dans la colonne Expires à la ligne {line}.
Import coupons: Importer des coupons
Download: Télécharger
Select a file: Sélectionnez un fichier
Choose: Choisir
You can import multiple coupons by uploading a file in CSV format.: Vous pouvez importer plusieurs coupons en téléchargeant un fichier au format CSV.
CSV file should contain following columns: Le fichier CSV doit contenir les colonnes suivantes
between 3 and 12 characters: "entre 3 et 12 caractères"
accepted values true, false, 0, 1: "valeurs acceptées: true, false, 0, 1"
0 = multi, 1 = single: "0 = multi, 1 = simple"
This field should be at least 3 characters long: Doit comporter au moins 3 caractères
The maximum length allowed is 12: La longueur maximale de 12 caractères
minLength: Doit comporter au moins {min} caractères
maxLength: Doit comporter au plus {max} caractères
Enter amount: Saisir le montant
required: Requis
The coupons has been imported successfully: "{count} coupons ont été importés avec succès."
The coupons has been deleted successfully: "{count} coupons ont été supprimés avec succès."
PLU1: PLU1
PLU2: PLU2
You can delete multiple coupons by uploading a file in CSV format: Vous pouvez supprimer plusieurs coupons en téléchargeant un fichier au format CSV.
Delete coupons: Supprimer des coupons
The coupon has been updated successfully.: Le coupon a été mis à jour avec succès.
The coupon has been deleted successfully.: Le coupon a été supprimé avec succès.
Update coupon: Mettre à jour le coupon
Create coupon: Créer un coupon
Edit the information and settings of this coupon.: Modifier les informations et les paramètres de ce coupon.
Coupon configuration: Configuration du coupon
Confirm: Confirmer
Are you sure you want to use this file and delete coupons?: Êtes-vous sûr de vouloir utiliser ce fichier et supprimer les <b>{totalLength}</b> coupons?
Are you sure you want to use this file and import coupons?: Êtes-vous sûr de vouloir utiliser ce fichier et importer les <b>{totalLength}</b> coupons?
fullAccess: Accès complet
Permission type: Type d'autorisation
Are you sure you want to delete this permission?: Êtes-vous sûr de vouloir supprimer cette autorisation?
Delete permission: Supprimer l'autorisation
Are you sure you want to delete this coupon?: Êtes-vous sûr de vouloir supprimer ce coupon?
Delete coupon: Supprimer le coupon
Coupon redemption: Rédemption de coupon
Redemption date: Date de rédemption
Single use coupon was reactivated on _ by _.: Le coupon à usage unique a été réactivé le <b>{date}</b> par <b>{username}</b>.
Currency: Devise
Demo location: Établissement de démonstration
Make this location a demonstration.: Faire de cet établissement une démonstration
Access the list of users with permissions for this location.: Accéder à la liste des utilisateurs ayant des permissions pour cet établissement.
Logo for this location: Logo pour cet établissement
Enter or edit the information and permissions for this location.: Saisir ou modifier les informations et les permissions pour cet établissement.
Location configuration: Configuration de l'établissement
Back to locations: Retour aux établissements
Location's information and logo.: Informations de l'établissement et logo
location_edit_sub_title: "Dernière mise à jour {last_update}"
Location groups: Groupes d'établissements
Add or remove this location from groups.: Ajouter ou supprimer cet établissement des groupes.
Add to group: Ajouter au groupe
Remove: Supprimer
Select a group you wish to add this location to.: Sélectionner un groupe auquel vous souhaitez ajouter cet établissement.
Are you sure you want to delete this group?: Êtes-vous sûr de vouloir supprimer ce groupe?
The location has been successfully detached from the group.: L'établissement a été supprimé avec succès du groupe.
The location has been successfully attached to the group.: L'établissement a été ajouté avec succès au groupe.
Detach location: Supprimer l'établissement
Are you sure you want to detach this location from this group?: Êtes-vous sûr de vouloir supprimer cet établissement de ce groupe?
group: Groupe
location: Établissement
Edit the cataloging information and activate/deactivate this coupon.: Modifier les informations de catalogage et activer/désactiver ce coupon.
If this coupon is available via your POS station, enter the Price Look-up code (PLU) of the veloce item added to the client order upon ordering the coupon.: Si ce coupon est disponible via votre station POS, saisir le code PLU de l'article veloce ajouté à la commande du client lors de la commande du coupon.
Redeeming a coupon means that the coupon has been used by a client to a location. You can view when and where the coupon has been redeemed in this section.: La rédemption d'un coupon signifie que le coupon a été utilisé par un client à un établissement. Vous pouvez voir quand et où le coupon a été réclamé dans cette section.
Redemption location: Établissement de rédemption
Coupon redeemed: Coupon réclamé
Closed: Fermé
Voided: Annulé
Training: Formation
Date/Time: Date/Heure
Cust.: Clients
Employee: Employé
Empl. No.: N° d'employé
Tender: Mode de paiement
Surcharge: Surcharge
Tip: Pourboire
Service: Service
Table: Table
Check number(s): N° de facture(s)
Tender type(s): Modes de paiements
POS number(s): N° de POS
Revenue center(s): Centres de revenus
Discounts(s): Remises
Enter number: Saisir le numéro
Date range: Intervalle de dates
Details: Détails
Customer(s): Client(s)
Revenue center: Centre de revenus
Time: Heure
Employee no.: N° d'employé
Qty: Qté
Additional fees: Frais supplémentaires
Tender types: Modes de paiement
Search filters: Filtres de recherche
Subtotal: Sous-total
Generate: Générer
Quit without saving: Quitter sans enregistrer
Your changes will be lost if you do not save them.: Vos modifications seront perdues si vous ne les enregistrez pas.
Would you like to save the changes made to the location %s?: Voulez-vous enregistrer les modifications apportées à l'établissement <b>{name}</b>?
Would you like to save the changes made to the user %s?: Voulez-vous enregistrer les modifications apportées à l'utilisateur <b>{firstName} {lastName}</b>?
Would you like to save the changes made to the group %s?: Voulez-vous enregistrer les modifications apportées au groupe <b>{name}</b>?
Would you like to save the changes made to the coupon %s?: Voulez-vous enregistrer les modifications apportées au coupon <b>{code}</b>?
Nothing to see here for now. Enter the required information to generate a check summary.: Rien à voir pour le moment. Saisir les informations requises pour générer un résumé de chèque.
Nothing to see here for now. Enter the required information to launch a check search.: Rien à voir pour le moment. Saisir les informations requises pour lancer une recherche de chèque.
Jamaica: Jamaïque
India: Inde
Select country: Sélectionner le pays
Permissions: Autorisations
Contact information: Informations de contact
Enter the information needed to catalog and contact this user. Enable the user by switching the toggle to on. Save your changes when you’re done.: Saisir les informations nécessaires pour cataloguer et contacter cet utilisateur. Activer l'utilisateur en passant le bouton à on. Enregistrer vos modifications lorsque vous avez terminé.
Control what this user has access to by adding a permission: Contrôler ce que cet utilisateur a accès en ajoutant une autorisation
Click on “Add permission”.: Cliquer sur « Ajouter une autorisation ».
Select a permission type, which can restrict the permission’s effect to a group or location.: Sélectionner un type d'autorisation, qui peut restreindre l'effet de l'autorisation à un groupe ou un établissement.
Set the permission.: Définir l'autorisation.
Save your changes when you’re done: Enregistrer vos modifications lorsque vous avez terminé
Coupon code: Code de coupon
A coupon code is the unique identifier of a coupon, as well as its associated UPC Barcode value.: Un code de coupon est l'identifiant unique d'un coupon, ainsi que sa valeur de code-barres UPC associée.
Coupon name: Nom du coupon
Add a name to your coupon for easier cataloging purposes.: Ajouter un nom à votre coupon pour faciliter le catalogage.
If provided, this amount is the forced price of the item with linked to PLU 1.: Si fourni, ce montant est le prix forcé de l'article lié au PLU 1.
If provided, this PLU references the Veloce item that must be ordered to grant right to previous item.: Si fourni, ce PLU fait référence à l'article Veloce qui doit être commandé pour accorder le droit à l'article précédent.
If provided and if PLU 2 is provided, this amount is the forced price of the selected item from PLU 2 effect.: Si fourni et si le PLU 2 est fourni, ce montant est le prix forcé de l'article sélectionné à partir de l'effet PLU 2.
Add the logo of this location for easier cataloging purposes.: Ajouter le logo de cet établissement pour faciliter le catalogage.
Sales: Ventes
Hours: Heures
Logo for this group: Logo pour ce groupe
No data available: Aucune donnée disponible
Png or jpg file, 600 x 400 pixels (landscape), 72 dpi.: Fichier png ou jpg, 600 x 400 pixels (paysage), 72 dpi.
Image requirements: Exigences de l'image
You can attribute one of 3 types to a group to help cataloging: Vous pouvez attribuer un des 3 types à un groupe pour aider au catalogage
Refers to one or more locations linked to the same franchise.: Fait référence à un ou plusieurs établissements liés à la même franchise.
Regroups more than one brand under one bigger entity.: Regroupe plus d'une marque sous une seule entité.
Personalized collection of groups.: Collection personnalisée de groupes.
Show %s %s: Afficher la sélection
Hide %s %s: Masquer la sélection
Check no.%s: Chèque n°{invoiceNumber}
Created by %s / Date/Time %s: Créé par {username} / Date/Heure {created}
Back to check summary: Retour à la page précédente
Group locations: Établissements du groupe
Add or remove locations for this group. A location can be part of more than one group.: Ajouter ou supprimer des établissements pour ce groupe. Un établissement peut faire partie de plus d'un groupe.
Enable multiselect to choose more than one or all locations.: Activer la sélection multiple pour choisir plus d'un ou tous les établissements.
Select one or more locations: Sélectionner un ou plusieurs établissements
Access the list of users with permissions for this location: Accéder à la liste des utilisateurs avec des autorisations pour cet établissement.
Check details: Détails de la facture
Browse and review specific checks.: Parcourir et examiner des factures spécifiques.
Search: Recherche
Select status: Sélectionner le statut
check_status:
  0: ""
  1: "Annulé"
  2: "Formation"
Upload: Télécharger
User: Utilisateur
Access this user for modifications.: Accéder à cet utilisateur pour des modifications.
Actions: Actions
Search for specific info, export your list, etc.: Rechercher des informations spécifiques, exporter votre liste, etc.
Dashboard options: Options du tableau de bord
Choose the settings and customize your dashboard.: Choisissez les paramètres et personnalisez votre tableau de bord.
Export users list: Exporter la liste des utilisateurs
Export: Exporter
Export coupons list: Exporter la liste des coupons
Select an option for your export.: Sélectionner une option pour votre exportation.
Category: Catégorie
Sub-category: Sous-catégorie
Category total: Total de la catégorie
Average / Customer: Moyenne / Client
Service charges: Frais de service
Total gross sales: Total des ventes brutes
Total revenue: Total des revenus
Gross sales: Ventes brutes
Total customer count: Nombre total de clients
Subtotal (Net + Taxes + Charges): Sous-total (Net + Taxes + Frais)
Total: Total
Taxable sales: Ventes taxables
Total net sales: Total des ventes nettes
Quantity: Quantité
Choose currency: Choisir la devise
Out of balance (Total revenue - Subtotal): Déséquilibre (Total des revenus - Sous-total)
Site status options: Options du statut du site
Choose the settings.: Choisissez les paramètres.
Search for specific informations in your list.: Rechercher des informations spécifiques dans votre liste.
Order mode: Mode de commande
Customers: Clients
Workstations: Caisses
Departments: Centres et revenus
Discounts volume: Volume des escomptes
Refresh locations list: Actualiser la liste des établissements
Refresh groups list: Actualiser la liste des groupes
Are you sure you want to reset your dashboard customization settings? Any change you may have made to your dashboard will be lost by performing this action.: Êtes-vous sûr de vouloir réinitialiser les paramètres de personnalisation de votre tableau de bord ? Toute modification que vous avez pu apporter à votre tableau de bord sera perdue en effectuant cette action.
Compare the selected date with a previous set of data.: Comparer la date sélectionnée avec un ensemble de données précédent.
Last week: La semaine dernière
Last month: Le mois dernier
Last 3 months: Les 3 derniers mois
Last year: L'année dernière
year: l'année dernière
quarter: les 3 derniers mois
month: le mois dernier
week: la semaine dernière
day: hier
Text alignment: Alignement du texte
Choose the presentation for the dashboard's data.: Choisissez la présentation des données du tableau de bord.
Reset settings: Réinitialiser
Dashboard settings: Paramètres du tableau de bord
Configure your dashboard with these options.: Configurez votre tableau de bord avec ces options.
Align left: Aligner à gauche
Align right: Aligner à droite
Align center: Aligner au centre
Vertical compact: Compacité verticale
Layout should be compact vertically.: La mise en page doit être compacte verticalement.
Total discounts: Total des escomptes
Payfacto. All rights reserved.: Payfacto. Tous droits réservés.
Meals count: Nombre de repas
Checks: Factures
checks: factures
Clients: Clients
Terms & Conditions: Conditions générales
Select language: Sélectionner la langue
Time format: Format de l'heure
Time display: Affichage de l'heure
Select your preferred default language.: Sélectionnez votre langue par défaut préférée.
Enter your current password and your new password.: Entrez votre mot de passe actuel et votre nouveau mot de passe.
Profile settings: Paramètres du profil
User profile: Profil de l'utilisateur
Behavior: Comportement
Set your log-in credentials and your contact email address.: Définissez vos identifiants de connexion et votre adresse e-mail de contact.
Change password: Changer le mot de passe
Select your time zone and default date and time format.: Sélectionnez votre fuseau horaire et le format de date et d'heure par défaut.
Personal info: Informations personnelles
Confirm new password: Confirmer le nouveau mot de passe
New password: Nouveau mot de passe
Current password: Mot de passe actuel
Change your password, language settings, etc.: Changez votre mot de passe, vos paramètres de langue, etc.
Date format: Format de la date
Time zone: Fuseau horaire
Interface main language: Langue principale de l'interface
%s locations: "{count} établissement(s)"
Funding: Financement
Redeem: Rachat
Outstanding Liability: Passif impayé
Check no.: Numéro de facture
Card number: Numéro de carte
Refund: Financement
Operation: Opération
Consolidated: Consolidé
Discount name: Nom de l'escompte
Nothing to see here for now. Enter the required information to generate the report.: Rien à voir pour le moment. Saisir les informations requises pour générer le rapport.
Activation: Activation
Refill: Ajout
Redemption Void: Annulation rachat
Redemption: Rachat
Activation Void: Annulation activation
Refill Void: Annulation ajout
Point Add: Ajout de points
Point Void: Annulation de points
Point Add Void: Ajout annulation de points
Point Activate: Activation de points
dates: dates
discounts: escomptes
gift cards: cartes cadeaux
Confirmation password doesn't match new password: Le mot de passe de confirmation ne correspond pas au nouveau mot de passe
24-Hour: 24 heures
12-Hour: 12 heures
Data trends: Tendances des données
Show data trends on dashboard.: Afficher les tendances des données sur le tableau de bord.
Increases are represented by green upward arrows: Les augmentations sont représentées par des flèches vertes vers le haut
Decreases are represented by red downward arrows: Les diminutions sont représentées par des flèches rouges vers le bas
Number of results on the graphs: Nombre de résultats sur les graphiques
Select the type of display for your data.: Sélectionnez le type d'affichage de vos données.
Type of chart to display by default: Type de graphique à afficher par défaut
List: Liste
Pie: Circulaire
Bar: Barres
%s employees: "Tous les employé(s)"
All %: Tous %
Item %: Item %
Gross sales volume: Volume des ventes brutes
Revenue center breakdown: Répartition par centres de revenus
revenue centers: centre de revenues
invoices: factures
employees: employés
French: Français
English: Anglais
Current password doesn't match your account password: Le mot de passe actuel ne correspond pas au mot de passe de votre compte
Enter a password: Saisir un mot de passe
At least one uppercase: Au moins une majuscule
At least one lowercase: Au moins une minuscule
At least one numeric: Au moins un numérique
Minimum 8 characters: Minimum 8 caractères
This year: Cette année
This month: Ce mois-ci
This week: Cette semaine
Today: Aujourd'hui
Period: Période
Authorized by: Autorisé par
Item description: Description de l'item
Item price: Prix de l'item
The main and secondary descriptions enable you to specify the default and alternative languages for displaying data, respectively. Choose which language to use when rendering the data.: Les descriptions principales et secondaires vous permettent de spécifier les langues par défaut et alternatives pour l'affichage des données, respectivement. Choisissez la langue à utiliser lors du rendu des données.
Main description: Description principale
Secondary description: Description secondaire
Gross sales (Top X): Ventes brutes (Top {top})
Voids: Annulations
Refunds: Remb.
Corrections: Corrections
Gift-Card-Sales-Report: Rapport-ventes-cartes-cadeaux
Employees-Sales-Report: Rapport-ventes-employes
Discount-Report: Rapport-escomptes
Voids-Report: Rapport-annulations
Net-Sales-Report: Rapport-ventes-nettes
Employee number: Numéro d'employé
January: Janvier
February: Février
March: Mars
April: Avril
May: Mai
June: Juin
July: Juillet
August: Août
September: Septembre
October: Octobre
November: Novembre
December: Décembre
Last day: Le dernier jour
Report settings: Paramètres du rapport
Configure your report with these options.: Configurez votre rapport avec ces options.
Report display: Affichage du rapport
Select the type of report you want to display.: Sélectionnez le type de rapport que vous souhaitez afficher.
Heatmap settings: Paramètres de la carte thermique
Select the data type you wish to display on the heatmap graph.: Sélectionnez le type de données que vous souhaitez afficher sur le graphique de la carte thermique.
Number of checks: Nombre de factures
Average / Check: Moyenne / Facture
Data table: Tableau de données
Heatmap: Carte thermique
Hourly-Sales-Report: Rapport-ventes-heure
Category-Sales-Report: Rapport-ventes-categorie
Number of customers: Nombre de clients
Income per customer: Revenu brute par client
No sales: Ouvertures de tiroir
X to X: "{from} à {to}"
Sales / Order mode: Ventes / Mode de commande
By clicking on the Login button, or by accessing or otherwise using the service, you agree to be bound by the: En cliquant sur le bouton <b>S'identifier</b>, ou en accédant ou en utilisant autrement le service, vous acceptez d'être lié par les
Coming soon!: Bientôt disponible!
This feature is under construction.: Cette fonctionnalité est en cours de construction.
Delivery: Livraison
Delivery Customers: Clients de livraison
Delivery Amount: Montant de livraison
Takeout Customers: Clients pour emporter
Takeout: Emporter
Takeout Amount: Montant pour emporter
Table Customers: Clients à table
Table Amount: Montant à table
Quick Service: Service rapide
Quick Service Customers: Clients de service rapide
Quick Service Amount: Montant de service rapide
Refunds & Voids: Remboursements et annulations
Cash: Espèces
Gross Sales / Workstation: Ventes brutes / Caisse
Tender Types: Modes de paiement
Queue Lane: File d'attente
Queue Lane Customers: Clients de la file d'attente
Queue Lane Amount: Montant de la file d'attente
Running Bill: Facture en cours
Running Bill Customers: Clients de facture en cours
Running Bill Amount: Montant de facture en cours
Refunds Quantity: Quantité de remboursements
Refunds Total: Total des remboursements
Refunds % of sales: % des remboursements
Voids Quantity: Quantité d'annulations
Voids % of sales: % des annulations
Item correction Quantity: Quantité de correction d'item
Item correction Total: Total de correction d'item
Cash Deposit: Dépôt en espèces
Paidouts: Déboursés
Tips: Pourboires
Gift load: Chargement de carte cadeau
Loyalty sales: Ventes de fidélité
Cash total: Total en espèces
Voids Total: Total des annulations
You have changed the settings for your rapport. Click on the Refresh button to generate a report with these settings.: Vous avez modifié les paramètres de votre rapport. Cliquez sur le bouton <b>Actualiser</b> pour générer un rapport avec ces paramètres.
Please refresh!: Veuillez actualiser!
sub-categories: sous-catégories
categories: catégories
Items-Sales-By-Category-Report: Rapport-ventes-items-par-categorie
All Percentage: Tous les pourcentages
Item Percentage: Pourcentage d'items
Average Daily amount: Montant quotidien moyen
Total Amount: Montant total
Total Quantity: Quantité totale
Summary settings: Paramètres du résumé
Configure your summary with these options.: Configurez votre résumé avec ces options.
Temporarily Unavailable: Temporairement indisponible
Compact dashboard vertically: Tableau de bord compact verticalement
Compact dashboard: Compacter le tableau de bord
Are you sure you want to proceed with compacting the dashboard tiles? This action will rearrange and optimize the layout of your dashboard tiles for better space utilization.: Êtes-vous sûr de vouloir procéder à la compaction des tuiles du tableau de bord? Cette action réorganisera et optimisera la disposition des tuiles de votre tableau de bord pour une meilleure utilisation de l'espace.
Select a value: Sélectionnez une valeur
No data: Aucune donnée
We have no data to show you for these report settings.: Nous n'avons pas de données à vous montrer pour ces paramètres de rapport.
Taxes total: Total des taxes
Charges: Frais
Charge: Frais
Charges total: Total des frais
Consent preferences: Préférences de consentement
Options: Options
Choose the settings and customize your report.: Choisissez les paramètres et personnalisez votre rapport.
The gross sales by revenue center will be grouped based on the check.: Les ventes brutes par centre de revenus seront regroupées en fonction de la facture.
The gross sales by revenue center will be grouped based on the items listed on the check.: Les ventes brutes par centre de revenus seront regroupées en fonction des items sur la facture.
The internet connection has been lost and that some functionalities may not be available until the connection is restored.: La connexion Internet a été perdue et certaines fonctionnalités peuvent ne pas être disponibles tant que la connexion n'est pas rétablie.
Connection Lost: Connexion perdue
We noticed you are currently using a previous version of Veloce Analytics. Refresh your page to access new features and improvements.: Nous avons remarqué que vous utilisez actuellement une version précédente de Veloce Analytics. Actualisez votre page pour accéder aux nouvelles fonctionnalités et améliorations.
New Veloce version available!: Nouvelle version de Veloce disponible!
Refresh now: Actualiser maintenant
No thanks, I will refresh later.: Non merci, je vais actualiser plus tard.
Continue: Continuer
The revenue center is based on the check.: Le centre de revenus est basé sur la facture.
Comparison period: Période de comparaison
Show last version: Afficher la dernière version
Show previous version: Afficher la version précédente
Gross sales by revenue center are grouped by invoice items.: Les ventes brutes par centre de revenus seront regroupées en fonction des items sur la facture.
Retain gratuity for payroll: Conserver le pourboire pour la paie
This option determines whether the gratuity amount will be deducted from the cash total or from the payroll.: Cette option détermine si le montant du pourboire sera déduit du total en espèces ou de la paie.
Date and time of the last activity on the server for the location in local time.: Date et heure de la dernière activité sur le serveur pour l'établissement en heure locale.
Date and time of the most recent check in local time of the location.: Date et heure de la dernière facture en heure locale de l'établissement.
Dates: Dates
Modes: Modes
New Version Available: Nouvelle version disponible
Try the new Veloce Analytics look: Essayez le nouveau look de Veloce Analytics
New Veloce Analytics Look: Nouveau look de Veloce Analytics
Click on this toggle to enable the new Veloce Analytics UI design.: Cliquez sur ce bouton pour activer le nouveau design de l'interface utilisateur de Veloce Analytics.
"% of sales": % des ventes
Sales last year: Ventes de l'année dernière
% to last year sales: % des ventes de l'année dernière
Meals: Repas
"% to last year meals": % des repas de l'année dernière
Revenue Center: Centre de revenus
Sales by Modes: Ventes par modes
Sales by Category: Ventes par catégorie
Sales by Revenue Center: Ventes par centre de revenus
Tips & Change: Pourboires et monnaie
Trans Count: Nombre de transactions
Total sales: Ventes totales
Gross sales + Tax: Ventes brutes + Taxes
Tax: Taxes
Cash Reconciliation: Réconciliation en espèces
Discount Summary (Amount net of tax): Résumé des escomptes (Montant net de taxe)
Tax Summary: Résumé des taxes
Item correction: Correction d'item
Avg Meals: Repas moyens
Statistics: Statistiques
Item corrections: Corrections d'items
Average: Moyenne
Count: Nombre
What's New?: Quoi de neuf?
Required if no invoice numbers specified: Requis si le champ numéro de facture est vide
Required if no date range specified. Multiple invoices can be entered seperated by a ','. Invoices range can be entered separated by a '-': Requis si aucune période de temps n'est renseignée. Plusieurs numéros de factures peuvent être entrés séparés par un ',' . Des groupes de factures peuvent être entrées séparés par '-'
Consolidated Sales: Ventes consolidées
Net Paid Ins/Outs: Entrées/Sorties nettes payées
Paid In/Out Summary: Résumé des entrées/sorties payées
itemCorrections: Correction d'item
refunds: remboursements
voids: annulations
Summary report: Rapport sommaire
Detailed report: Rapport détaillé
noSales: Ouvertures de tiroir
Report presets: Préréglages de rapport
Save as: Enregistrer sous
My report presets: Mes préréglages de rapport
Select a set of saved presets for your report.: Sélectionnez un ensemble de préréglages enregistrés pour votre rapport.
There are no saved presets.: Il n'y a pas de préréglages enregistrés.
Save presets as: Enregistrer les préréglages sous
Are you sure you want to delete these presets?: Êtes-vous sûr de vouloir supprimer ces préréglages?
Delete presets: Supprimer les préréglages
Delete filters: Supprimer les filtres
Rename presets: Renommer les préréglages
Generate a summarizing categories report.: Générer un rapport sommaire par catégories.
Check average: Moyenne de facture
Waiter Performance: Perfomance des employés
Employee name: Nom de l'employé
Summary Sales: Ventes sommaires
Generate a performance report for each waiter.: Générer un rapport de performance pour chaque employé.
Review sales reports by employees.: Examiner les rapports de ventes par employés.
Report filters: Filtres de rapport
My report filters: Mes filtres de rapport
Select a set of saved filters for your report.: Sélectionnez un ensemble de filtres enregistrés pour votre rapport.
There are no saved filters.: Il n'y a pas de filtres enregistrés.
Are you sure you want to delete these filters?: Êtes-vous sûr de vouloir supprimer ces filtres?
Rename filters: Renommer les filtres
Save filters as: Enregistrer les filtres sous
Additionnal fees: Frais supplémentaires
Gratuity: Pourboire
Employee-Performance-Report: Rapport-performance-employe
vendus: vendus
Nothing to see here for now. Enter the required information to generate the employee performance.: Rien à voir pour le moment. Saisir les informations requises pour générer la performance de l'employé.
Previous week sales %s: Ventes de la semaine précédente ({from})
Previous month sales %s: Ventes du mois précédent ({from})
Previous year sales %s: Ventes de l'année précédente ({from})
Sales %s: Ventes ({from})
Previous week sales %s %s: Ventes de la semaine précédente ({from} au {to})
Previous month sales %s %s: Ventes du mois précédent ({from} au {to})
Previous year sales %s %s: Ventes de l'année précédente ({from} au {to})
Sales %s %s: Ventes ({from} au {to})
Total Discounts: Total des escomptes
Number of items sold: Nombre d'items vendus
Brand: Marque
Enterprise: Entreprise
corrections: corrections
Too many invoices: Trop de factures. Veuillez réduire le nombre de factures.
An error has occurred: Une erreur est survenue
An error has occurred, please try again later.: Une erreur est survenue, veuillez réessayer plus tard.
Dev mode: Mode développeur
Break ajax requests: Interrompre les requêtes ajax
An error occurred: Une erreur est survenue
An error occurred while processing your request. Please refresh the page.: Une erreur est survenue lors du traitement de votre demande. Veuillez actualiser la page.
Break exports:  Interrompre les exports
Enable global caching: Activer le cache global
Show execution time: Afficher le temps d'exécution
Date: Date
not tracked: non suivi
exec time.: temps d'exécution
content length: taille du contenu
loading: chargement
requests: requêtes
Clear cache: Effacer le cache
Reload: Recharger
yes: oui
no: non
Show backend errors: Afficher les erreurs du backend
API Errors: Erreurs API
Clear Errors: Effacer les erreurs
Close: Fermer
Edit params: Modifier les paramètres
Submit: Soumettre
api errors: erreurs API
Some filters cannot be applied with the current data.: Certains filtres ne peuvent pas être appliqués avec les données actuelles.
No Matches Found: Aucune correspondance trouvée
It seems like your current filter settings are too restrictive. Try removing or modifying some filters to broaden your search.: Il semble que vos paramètres de filtre actuels soient trop restrictifs. Essayez de supprimer ou de modifier certains filtres pour élargir votre recherche.
Focus on content: Se concentrer sur le contenu
toggle language: basculer la langue
Visible on staging only: Visible uniquement en staging
See a short documentation: Voir documentation
Manager Activity: Activité du gestionnaire
Start: Début
End: Fin
Server: Serveur
Manager-Activity-Report: Rapport-activite-gestionnaire
SRM Validation: Validation MEV
Validate: Valider
Utility: Utilitaire
Registration name: Nom d'enregistrement
Identification Number: Numéro d'identification
Organization: Organisation
Authorization Code: Code d'Autorisation
Billing File Number: Numéro de dossier de facturation
Civic number: Numéro civique
Postal code: Code postal
GST Tax number: Numéro de taxe TPS
QST Tax number: Numéro de taxe TVQ
Registration Details: Détails d'enregistrement
Provide your identification number.: Fournir votre numéro d'identification.
Organization Details: Détails de l'organisation
Enter your organization code and billing file number.: Saisir votre code d'organisation et numéro de dossier de facturation.
Address Details: Détails de l'adresse
Provide your civic number and postal code.: Fournir votre numéro civique et votre code postal.
Tax Information: Informations fiscales
Enter your QST tax number.: Saisir vos numéros de TVQ.
Additional Details: Détails Additionnels
Enter additional details about the validation: Entrer des détails additionnels sur la validation
Context Note: Note de contexte
The Identification Number must be 10 digits.: Le numéro d'identification doit comporter 10 chiffres.
The Authorization Code must be in format X9X9-X9X9.: Le code d'autorisation doit être au format X9X9-X9X9.
The Billing File Number must be in format AA9999.: Le numéro de dossier de facturation doit être au format ER9999.
The QST Tax Number must be in format 9999999999TQ9999.: Le numéro de taxe TVQ doit être au format 9999999999TQ9999.
Context note must not exceed 2048 characters: La note de contexte ne peut pas excèder 2048 charactères.
License number must not exceed 128 characters: La license ne peut pas excèder 128 charactères.
The provided information is incorrect: L'information fournie est incorrecte.
The provided information is valid: L'information fournie est valide.
The certificate was succesfuly created and deleted by the mev: Le certificat a été créé et supprimé avec succès par le MEV
An error has occured communicating with the mev: Une erreur est servenue lors de la communication avec le MEV
MEV Validation Result: Résultat Validation MEV
JW00B999470E: Le numéro d'identification est invalide
JW00B999429E: Le code d'organisation (construit à partir du code d'autorisation) est invalide
JW00B999514E: Le code d'autorisation est invalide ou ne correspond pas au numéro d'identification
JW00B999615E: Le numéro de dossier de facturation est invalide ou ne correspond pas au numéro de taxe TVQ
JW00B999614E: Le numéro de dossier de facturation est invalide ou ne correspond pas au code d'autorisation
JW00B999430E: Le numéro de taxe TVQ est invalide
Get access to SRM validation.: Accéder à la validation MEV.
